// Override default variables before the import
// See node_modules/bootstrap/scss/_variables.scss
$body-bg: #223344;
$body-color: whitesmoke;

@import '~bootstrap/scss/bootstrap'; // Import Bootstrap and its default variables
@import '~bootstrap-icons/font/bootstrap-icons.css'; // See https://icons.getbootstrap.com/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2em;
}

h1 {
  border-bottom: 1px solid whitesmoke;
  margin-top: 40px;
  padding-bottom: 2rem;
  text-align: center;
}

h2 {
  padding-bottom: 2rem;
  text-align: center;
}

h2,
h3 {
  color: wheat;
}

a,
a:visited,
a:active {
  color: skyblue;
}

a:hover {
  color: skyblue;
}

a {
  text-decoration: none;
  font-size: 1.5em;
}

ul {
  list-style: none;
  margin-block-start: 0;
  padding-inline-start: 0;
  margin-block-end: 0;
  padding-bottom: 2rem;
}

.row {
  padding-bottom: 2rem;
}

.signed {
  text-align: right;
  font-style: italic;
  color: yellow;
}
